<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-row class="w-100">
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="search"
                  placeholder="Kullanıcı Ara"
                  @keydown.enter="getFilterData"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    :disabled="search === ''"
                    @click="getFilterData"
                  ><FeatherIcon icon="SearchIcon" /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <v-select
                id="id_com_location"
                v-model="filterData.id_com_location"
                label="title"
                :reduce="location => location.id"
                :options="getLocations"
                placeholder="Lokasyon"
              />
            </b-col>
            <b-col>
              <v-select
                id="id_com_brand"
                v-model="filterData.id_com_brand"
                label="name"
                :reduce="brand => brand.id"
                :options="getBrands"
                placeholder="Marka"
              />
            </b-col>
            <b-col>
              <v-select
                id="id_com_user_type"
                v-model="filterData.id_com_user_type"
                label="title"
                :reduce="type => type.id"
                :options="getUserTypes"
                placeholder="Kullanıcı Tipi"
              />
            </b-col>
            <b-col>
              <v-select
                id="status"
                v-model="filterData.status"
                label="title"
                :reduce="status => status.id"
                :options="statuses"
                placeholder="Durum"
              />
            </b-col>
            <b-col md="auto">
              <b-button-group>
                <b-button
                  variant="outline-primary"
                  @click="getFilterData"
                >
                  <FeatherIcon icon="FilterIcon" />
                </b-button>
                <b-button
                  to="users/add"
                  variant="outline-primary"
                ><FeatherIcon icon="PlusIcon" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-table
          responsive
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(name)="data">
            <div>
              <span class="font-weight-bold">
                {{ data.item.name }}
              </span>
              <b-badge
                class="font-small-1"
                variant="light-secondary"
              >
                <feather-icon icon="UsersIcon" />
                {{ data.item.id }}
              </b-badge>
            </div>
            <b-badge
              class="mt-1"
              variant="light-info"
            >
              <feather-icon icon="MailIcon" />
              {{ data.item.email }}
            </b-badge>
            <div class="mt-percent-2">
              <b-badge variant="light-dark">
                <feather-icon icon="UserIcon" />
                <b>Yönetici: </b>{{ data.item.manager_name }}</b-badge>
            </div>
          </template>
          <template #cell(brand)="data">
            <div>{{ data.item.brand }}</div>
            <b-badge
              class="mt-1"
              variant="light-warning"
            >
              <feather-icon icon="FolderIcon" />
              {{ data.item.department }}
            </b-badge>
            <div class="mt-percent-2">
              <b-badge variant="light-info">
                <feather-icon icon="StarIcon" />
                {{ data.item.user_type }}
              </b-badge>
            </div>
          </template>
          <template #cell(location)="data">
            <b-badge
              variant="light-secondary"
            >
              <feather-icon icon="MapPinIcon" />
              {{ data.item.location }}
            </b-badge>
            <div
              v-if="data.item.webform === '1'"
              class="mt-1"
            >
              <b-badge :variant="data.item.webform === '1' ? 'light-success' : 'light-danger'">
                <feather-icon :icon="data.item.webform === '1' ? 'CheckIcon' : 'XIcon'" />
                Webform Alabilir
              </b-badge>
            </div>
            <div
              v-if="data.item.cron === '1'"
              class="mt-percent-2"
            >
              <b-badge :variant="data.item.cron === '1' ? 'light-success' : 'light-danger'">
                <feather-icon :icon="data.item.cron === '1' ? 'CheckIcon' : 'XIcon'" />
                Zamanlanmış Görevler
              </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-center">
              <b-badge
                v-if="data.item.status == 1"
                variant="light-success"
              >
                <FeatherIcon
                  icon="CheckIcon"
                  size="20"
                  class="text-success"
                />
              </b-badge>
              <b-badge
                v-else
                variant="light-danger"
              >
                <FeatherIcon
                  icon="XIcon"
                  size="20"
                  class="text-danger"
                />
              </b-badge>

            </div>
          </template>
          <template #cell(control)="data">
            <div class="text-right">
              <b-dropdown
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item :to="'/users/view/' + data.item.id">
                  Görüntüle
                </b-dropdown-item>
                <b-dropdown-item :to="'/users/edit/' + data.item.id">
                  Güncelle
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="changeStatus(data.item.status,data.item.id)">
                  {{ (data.item.status === '1')? 'Hesabı Kapat' : 'Hesabı Aç' }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BDropdown, BDropdownDivider, BDropdownItem, BButtonGroup, BBadge, BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    vSelect,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BButtonGroup,
    BBadge,
    BCardHeader,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      statuses: [
        {
          id: '1',
          title: 'Aktif',
        },
        {
          id: '0',
          title: 'Pasif',
        },
      ],
      filterData: {
        id_com_location: null,
        id_com_user_type: null,
        id_com_brand: null,
        status: null,
      },
      fields: [
        {
          key: 'name',
          label: 'Adı Soyadı',
        },
        {
          key: 'brand',
          label: 'Departman',
          thStyle: { width: '220px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'location',
          label: 'Lokasyon',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '50px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_user.id          AS id',
          'com_user.name        AS name',
          'com_user.email       AS email',
          'com_user.status      AS status',
          'com_user.webform     AS webform',
          'com_user.cron        AS cron',
          'com_brand.name       AS brand',
          'com_department.title AS department',
          'com_location.title   AS location',
          'com_user_type.title  AS user_type',
          'user_manager.name    AS manager_name',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['users/getUsers']
      return data
    },
    dataCount() {
      return this.$store.getters['users/getUsersCount']
    },
    saveStatus() {
      return this.$store.getters['users/getUserSaveStatus']
    },
    getLocations() {
      return this.$store.getters['locations/getLocations']
    },
    getBrands() {
      return this.$store.getters['brands/getBrands']
    },
    getUserTypes() {
      return this.$store.getters['userTypes/getUser_types']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
      }
      this.user.submitStatus = false
    },
  },
  created() {
    this.getDataList()
    this.locations()
    this.brands()
    this.userTypes()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('users/usersList', this.dataQuery)
    },
    getFilterData() {
      this.dataQuery.where = {}
      this.dataQuery.or_like = {}
      if (this.filterData.id_com_location) {
        this.dataQuery.where['com_user.id_com_location'] = this.filterData.id_com_location
      }

      if (this.filterData.id_com_brand) {
        this.dataQuery.where['com_user.id_com_brand'] = this.filterData.id_com_brand
      }

      if (this.filterData.id_com_user_type) {
        this.dataQuery.where['com_user.id_com_user_type'] = this.filterData.id_com_user_type
      }
      if (this.search) {
        this.dataQuery.or_like = {
          'com_user.name': this.search,
          'com_user.email': this.search,
        }
      }
      if (this.filterData.status) {
        this.dataQuery.where['com_user.status'] = this.filterData.status
      }
      this.getDataList()
    },
    locations() {
      return this.$store.dispatch('locations/locationsList')
    },
    brands() {
      return this.$store.dispatch('brands/brandsList')
    },
    userTypes() {
      return this.$store.dispatch('userTypes/user_typesList')
    },
    changeStatus(status, id) {
      const userStatus = Number(status)
      this.$store.dispatch('users/userSave', {
        id,
        status: !userStatus,
      })
      this.getDataList()
    },
  },
}
</script>

<style scoped>
.mt-percent-2{
  margin-top: 2%
}
</style>
